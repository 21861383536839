import { Box, Button, Card, CardContent, CardHeader, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSettings, updateSettings } from "src/services/api/api.settings.service";
import { ISettings } from "src/types/settings";
import Grid from "@mui/material/Unstable_Grid2";

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  const [settings, setSettings] = useState<ISettings>({
    upgrade_rtp: 0,
    contract_rtp: 0,
    crash_rtp: 0
  });

  useEffect(() => {
    getSettings().then((data) => {
      setSettings(data);
    });
  }, []);

  const isSettingsValid = useMemo(() => {
    return settings.upgrade_rtp > 0 && settings.upgrade_rtp <= 1 &&
      settings.contract_rtp > 0 && settings.contract_rtp <= 1 &&
      settings.crash_rtp > 0 && settings.crash_rtp <= 1;
  }, [settings]);

  const setRtpValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const normalizedValue = value.replace(",", ".");
    let parsedValue = parseFloat(normalizedValue);

    if (!parsedValue) {
      setSettings({ ...settings, [name]: value });
    }

    if (parsedValue < 0) {
      parsedValue = 0;
    } else if (parsedValue > 1) {
      parsedValue = 1;
    }

    setSettings({ ...settings, [name]: parsedValue });
  }

  const saveSettings = async () => {
    await updateSettings(settings);
  }

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Card sx={{ width: '80%' }}>
        <CardHeader title={t("SETTINGS.RTP")} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid lg={12}>
              <TextField
                name="upgrade_rtp"
                size="small"
                value={settings?.upgrade_rtp}
                label={t('SETTINGS.UPGRADE_RTP')}
                onChange={setRtpValue}
                fullWidth
                required
                type="number"
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.01,
                }}
              >
              </TextField>
            </Grid>
            {/* <Grid lg={12}>
              <TextField
                name="contract_rtp"
                size="small"
                value={settings?.contract_rtp}
                label={t('SETTINGS.CONTRACT_RTP')}
                onChange={setRtpValue}
                fullWidth
                required
                type="number"
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.01,
                }}
              >
              </TextField>
            </Grid>
            <Grid lg={12}>
              <TextField
                name="crash_rtp"
                size="small"
                value={settings?.crash_rtp}
                label={t('SETTINGS.CRASH_RTP')}
                onChange={setRtpValue}
                fullWidth
                required
                type="number"
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.01,
                }}
              >
              </TextField>
            </Grid> */}
          </Grid>
          <Grid lg={12}>
            <Button onClick={saveSettings} sx={{ margin: '20px 0' }} fullWidth variant="contained" disabled={!isSettingsValid}>{t("COMMON.SAVE")}</Button>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SettingsPage;
