import React, { useCallback, useEffect, useMemo, useState } from "react";
import { imageUrl } from "src/services/api/api.config";
import { deleteCase, getCases } from "src/services/api/api.cases.service";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  type SelectChangeEvent,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Flask, MagnifyingGlass, Pencil, Plus } from "@phosphor-icons/react";
import { Trash as TrashIcon } from "@phosphor-icons/react/dist/ssr/Trash";
import { useTranslation } from "react-i18next";

import { CaseFilter, type Case, type Category } from "src/types/cases";
import { DeleteDialog } from "src/components/common/dialog-delete";
import { StyledTableCell } from "src/components/common/table-cell";

import CaseDialog from "./case-dialog/case-dialog";
import CaseTestDialog from "./case-test/case-test-dialog";
import { getCategories } from "src/services/api/api.categories.service";
import Grid from "@mui/material/Unstable_Grid2";
import { CASE_DATA_REFRESH_INTERVAL } from "src/constants/case.constants";
import { filterCases } from "./lib/filter-cases";

export function CasesTable() {
  const { t } = useTranslation();
  const [visibleData, setVisibleData] = useState<Case[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTestDialog, setOpenTestDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Case>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredData, setFilteredData] = useState<Case[]>([]);
  const [filter, setFilter] = useState<CaseFilter>({});
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(100);
  const [loading, setLoading] = useState(true);

  const refresh = useCallback(() => {
    Promise.all([getCategories(), getCases()])
      .then((data) => {
        const [categories, cases] = data;

        setCategories(categories);
        setVisibleData(cases);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    refresh();

    const interval = setInterval(() => {
      refresh();
    }, CASE_DATA_REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [refresh]);

  useEffect(() => {
    const filteredCases = filterCases(visibleData, filter);

    setFilteredData(filteredCases);
  }, [visibleData, filter]);

  const handleSelectChange = (e: SelectChangeEvent) => {
    const selectedCategory = e.target.value;

    setFilter((prev) => {
      if (selectedCategory !== prev.category) {
        return { ...prev, category: selectedCategory };
      }

      return prev;
    });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();

    setFilter((prev) => {
      if (value !== prev.searchString) {
        return { ...prev, searchString: value };
      }

      return prev;
    });
  };

  const visibleRows = useMemo(() => {
    return filteredData.slice(
      page * rowsPerPageState,
      page * rowsPerPageState + rowsPerPageState
    );
  }, [filteredData, page, rowsPerPageState]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageState(parseInt(event.target.value));
    setPage(0);
  };

  const onCloseDialog = (needRefresh: boolean) => {
    if (needRefresh) {
      refresh();
    }
    setOpenDialog(false);
    setSelectedCase(undefined);
  };

  const onAddOrEdit = (item?: Case) => {
    setSelectedCase(item);
    setOpenDialog(true);
  };

  const onTest = (item: Case) => {
    setOpenTestDialog(true);
    setSelectedCase(item);
  };

  const onCloseTestDialog = () => {
    setOpenTestDialog(false);
    setSelectedCase(undefined);
  };

  const onDelete = (item: Case) => {
    setOpenDeleteDialog(true);
    setSelectedCase(item);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCase(undefined);
  };
  const handleDeleteItem = async () => {
    setOpenDeleteDialog(false);
    if (selectedCase) {
      await deleteCase(selectedCase.id);
    }

    refresh();
  };

  return (
    <Stack spacing={1}>
      <Grid container spacing={1}>
        <Grid lg={4}>
          <OutlinedInput
            size="small"
            defaultValue=""
            fullWidth
            placeholder={t("CASES.CASE")}
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlass fontSize="var(--icon-fontSize-md)" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
            onChange={handleFilter}
          />
        </Grid>
        <Grid lg={4}>
          <FormControl fullWidth size="small">
            <InputLabel> {t("CASES.CATEGORY")}</InputLabel>
            <Select
              label={t("CASES.CATEGORY")}
              value={filter?.category || ""}
              onChange={handleSelectChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {categories.map((category, index) => (
                <MenuItem key={index} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid lg={2}></Grid>
        <Grid lg={2} display={"flex"} justifyContent={"end"}>
          <Button
            startIcon={<Plus fontSize="var(--icon-fontSize-md)" />}
            variant="contained"
            onClick={() => onAddOrEdit()}
          >
            {t("COMMON.ADD")}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ overflowX: "auto" }}>
        <Table sx={{ minWidth: "800px" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell width={"10%"}>{t("COMMON.ID")}</StyledTableCell>
              <StyledTableCell width={"15%"}>
                {t("COMMON.IMAGE")}
              </StyledTableCell>
              <StyledTableCell width={"20%"}>
                {t("COMMON.TITLE")}
              </StyledTableCell>
              <StyledTableCell width={"10%"}>
                {t("CASES.OPENING_PRICE")}
              </StyledTableCell>
              <StyledTableCell width={"10%"}>
                {t("COMMON.MARKET_ORDER")}
              </StyledTableCell>
              <StyledTableCell width={"10%"}>{t("CASES.RTP")}</StyledTableCell>
              <StyledTableCell width={"10%"}>
                {t("CASES.CATEGORY")}
              </StyledTableCell>
              <StyledTableCell width={"15%"} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="circular" width={30} height={30} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction={"row"} justifyContent={"end"}>
                        <Skeleton variant="circular" width={24} height={24} />
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                ))
              : visibleRows?.map((row) => {
                  return (
                    <TableRow hover key={row.id}>
                      <StyledTableCell>{row.id}</StyledTableCell>
                      <StyledTableCell>
                        <img
                          width={40}
                          height={40}
                          src={imageUrl(row.img_path)}
                          alt={row.name || "Case image"}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.price_of_opening}</StyledTableCell>
                      <StyledTableCell>{row.order_in_market}</StyledTableCell>
                      <StyledTableCell>{row.rtp}</StyledTableCell>
                      <StyledTableCell>{row.category}</StyledTableCell>
                      <StyledTableCell>
                        <Stack direction={"row"} justifyContent={"end"}>
                          <IconButton
                            onClick={() => {
                              onTest(row);
                            }}
                          >
                            <Flask />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              onAddOrEdit(row);
                            }}
                          >
                            <Pencil />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              onDelete(row);
                            }}
                          >
                            <TrashIcon />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPageState}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("COMMON.ROWS_PER_PAGE")}
        />
        <CaseDialog
          item={selectedCase ?? ({} as Case)}
          onCloseDialog={onCloseDialog}
          openDialog={openDialog}
          categories={categories}
        />
        <CaseTestDialog
          item={selectedCase ?? ({} as Case)}
          onCloseDialog={onCloseTestDialog}
          openDialog={openTestDialog}
        />
        <DeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onDelete={handleDeleteItem}
          itemName={selectedCase?.name ?? ""}
        />
      </Box>
    </Stack>
  );
}
