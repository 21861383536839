import { Case, CaseFilter } from "src/types/cases";

export const filterCases = (cases: Case[], filter: CaseFilter): Case[] => {
  const { category, searchString } = filter;

  return cases.filter((caseItem) => {
    return (
      (!category || caseItem.category === category) &&
      (!searchString ||
        caseItem.name?.toLowerCase().includes(searchString.toLowerCase()))
    );
  });
};
